<template>
  <div id="change-account" v-if="renderer">
    <ModalInsertAccountPinTemplate v-if="flagShowModal" :showModal="showModal" :user="selectedUser" :isUnlink="isUnlink"
      :getAccounts="getAccounts" :currentGuid="currentGuid" />

    <NavbarChangeAccount class="change-account__navbar-change-account" />

    <header class="change-account__header">
      <h1>
        {{ $t("ChangeAccountPage.Title") }}
      </h1>

      <h2>
        {{ $t("ChangeAccountPage.Subtitle") }}
      </h2>
    </header>
    <b-input-group class="search-bar-input my-3">
      <b-form-input v-model="searchQuery" :placeholder="$t('ChangeAccountPage.SearchPlaceholderDefault')"
        class="search-input">
      </b-form-input>
      <b-input-group-append>
        <b-button variant="link" class="search-button" @click="filterAccounts">
          <SearchIcon class="search-icon" />
        </b-button>
      </b-input-group-append>
    </b-input-group>

    <div class="cards-container" v-if="flagRenderList && filteredAccounts.length">
      <div class="card-container add-account-container" @click="logoutChangeAccount">
        <span>
          <AddIcon v-if="widthSize > 570" />
          <AddSmallIcon v-else />
        </span>

        <span class="add-account">
          {{ $t("ChangeAccountPage.AddAccount") }}
        </span>
      </div>

      <div v-for="(account, index) in filteredAccounts" :key="`account-${index}`" class="card-container">
        <button v-if="manageAcess != undefined" class="btn-close" @click="clickModal('unlink', account)">
          <span>
            <CloseIcon v-if="widthSize > 570" />
            <CloseSmallIcon v-else />
          </span>
        </button>

        <div class="card-content" @click="clickModal('card', account)">
          <div class="account-photo">
            <img :src="account.photo" alt="foto do usuário" v-if="account.photo" />
            <span v-else>
              {{
                account.first_name[0] + account.last_name.split(" ").at(-1)[0]
              }}
            </span>
          </div>

          <div class="account-details">
            <span class="account-name add-ellipsis-change-account">
              {{ `${account.first_name} ${account.last_name}` }}
            </span>

            <span class="account-info add-ellipsis-change-account">
              {{ account.job_role }}
            </span>

            <span class="account-info add-ellipsis-change-account">
              {{ account.employee_id }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="flagRenderList && !filteredAccounts.length" class="no-results">
      <div class="no-results-container">
        <div class="no-results-content">
          <SearchNoResultIcon alt="No Results" class="no-results-icon" />
          <p class="no-results-text">{{ $t("ChangeAccountPage.NoResultsFound") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@/assets/images/icons/close-simple-icon.svg";
import CloseSmallIcon from "@/assets/images/icons/close-simple-small-icon.svg";
import AddIcon from "@/assets/images/icons/add-circle-icon-edit.svg";
import AddSmallIcon from "@/assets/images/icons/add-circle-small-icon2.svg";
import SearchIcon from "@/assets/images/icons/search-icon-gray.svg";
import SearchNoResultIcon from "@/assets/images/icons/search-no-result-icon.svg";
import ModalInsertAccountPinTemplate from "@/@core/components/b-modal/ModalChangeAccount/ModalInsertAccountTemplate.vue";
import NavbarChangeAccount from "./NavbarChangeAccount.vue";
import { initialAbility } from "@/libs/acl/config";
import { mapMutations } from "vuex";
import {
  BFormInput,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    AddIcon,
    AddSmallIcon,
    CloseSmallIcon,
    CloseIcon,
    SearchIcon,
    SearchNoResultIcon,
    ModalInsertAccountPinTemplate,
    NavbarChangeAccount,
    BFormInput,
    BButton,
  },
  data() {
    return {
      prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
      manageAcess: JSON.parse(localStorage.getItem('userData')).ability.find(element => element.action == 'manage'),
      currentGuid: JSON.parse(localStorage.getItem('userData')).guid,
      widthSize: window.screen.width,
      flagShowModal: false,
      isUnlink: false,
      flagRenderList: true,
      accounts: [],
      filteredAccounts: [],
      selectedUser: {},
      renderer: true,
      searchQuery: "",
    }
  },
  async mounted() {
    await this.addInfosInLoggedAccountsInfo()
    this.getAccounts()
    this.filteredAccounts = this.accounts;
    localStorage.removeItem('accessToken')
  },
  beforeCreate() {
    const hasUserData = localStorage.getItem('userData');
    if (!hasUserData) {
      const previousAccountsLogged = localStorage?.getItem('previousAccountsLogged')
      localStorage.setItem('userData', previousAccountsLogged)
      localStorage.removeItem('previousAccountsLogged')
    }
  },

  methods: {
    ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
    showModal(action) {
      this.flagShowModal = action === "open";
      this.selectedUser = action === "close" ? {} : this.selectedUser;
    },
    getAccounts() {
      this.accounts = JSON.parse(localStorage.getItem("loggedAccountsInfo"));
      this.UPDATE_FLAG_SHOW_OVERLAY(false);
    },
    filterAccounts() {
      const trimmedQuery = this.searchQuery.trim().toLowerCase();
      const words = trimmedQuery.split(/\s+/);

      if (!trimmedQuery) {
        this.filteredAccounts = this.accounts;
      } else {
        this.filteredAccounts = this.accounts.filter(acc => {
          const firstNameMatch = acc.first_name.toLowerCase().includes(trimmedQuery);
          const lastNameMatch = acc.last_name.toLowerCase().includes(trimmedQuery);
          const jobRoleMatch = acc.job_role.toLowerCase().includes(trimmedQuery);
          const fullNameMatch = `${acc.first_name.toLowerCase()} ${acc.last_name.toLowerCase()}`.includes(trimmedQuery);

          return firstNameMatch || lastNameMatch || jobRoleMatch || fullNameMatch;
        });
      }
    },
    addInfosInLoggedAccountsInfo() {
      const infoAccountsLogin = localStorage.getItem("infoAccountsLogin");
      const loggedAccountsInfo = localStorage.getItem("loggedAccountsInfo");
      const userInfo = this.$cookies.get("userInfo");

      if (!!!infoAccountsLogin) {
        localStorage.removeItem("infoAccountsLogin");
        this.flagRenderList = true;

        return;
      }

      const payload = {
        guids: [JSON.parse(infoAccountsLogin).guid],
      };

      if (payload?.length === 0) {
        this.clearLocalStorageAndRedirectToLogin();

        return;
      }
      if (userInfo && loggedAccountsInfo) {
        const infoAccount = {
          guid: userInfo.userData.guid,
          employee_id: userInfo.userData.employee_id,
          first_name: userInfo.userData.first_name,
          last_name: userInfo.userData.last_name,
          job_role: userInfo.userData.job_role,
          photo: userInfo.userData.photo,
        };

        if (
          !JSON.parse(loggedAccountsInfo).find(
            (item) => item.guid == userInfo.userData.guid
          )
        ) {
          var guidAtual = JSON.parse(loggedAccountsInfo);
          guidAtual.unshift(infoAccount);
          localStorage.setItem("loggedAccountsInfo", JSON.stringify(guidAtual));
        } else {
          var guidAtual = JSON.parse(loggedAccountsInfo);
          const index = guidAtual.findIndex(
            (item) => item.guid === infoAccount.guid
          );
          if (index !== -1) {
            guidAtual.splice(index, 1);
          }
          guidAtual.unshift(infoAccount);
          localStorage.setItem("loggedAccountsInfo", JSON.stringify(guidAtual));
        }
      } else {
        localStorage.setItem(
          "loggedAccountsInfo",
          JSON.stringify([infoAccount])
        );
      }
      this.flagRenderList = true;
    },
    clearLocalStorageAndRedirectToLogin() {
      localStorage.clear();
      this.$router.push({ name: "login" });
    },
    logoutChangeAccount() {
      const userData = localStorage.getItem("userData");
      localStorage.setItem("previousAccountsLogged", userData);
      localStorage.removeItem("userData");
      localStorage.removeItem("sites");
      localStorage.removeItem("reloaded");
      // Reset ability
      this.$ability.update(initialAbility);
      // Redirect to multiple-login page
      window.location.href = `${this.$myIndLink}multiple-account`;
    },
    clickModal(local, user) {
      if (user) this.selectedUser = user;
      this.isUnlink = local === "unlink";

      this.showModal("open");
    },
  },
};
</script>

<style lang="scss">
.app-content-container:has(#change-account) {
  min-height: 100vh;
  padding-left: 0;

  .app-content {
    padding-top: 0 !important;
  }
}

.search-bar-input {
  position: relative;
}

.search-input {
  padding-right: 30px;
}

.search-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0.375rem 0.75rem;
  background-color: transparent;
  border: none;
}

.search-icon {
  height: 14px;
  width: 14px;
}

.no-results {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  margin-top: 24px;
}

.no-results-container {
  width: 1215px;
  height: 484px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
}

.no-results-content {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-results-icon {
  width: 80px;
  height: 80px;
  margin-top: 24px;
}

.no-results-text {
  margin-top: 24px;
  color: #4C4541;
  text-align: center;
  font-variant-numeric: lining-nums, proportional-nums;
  font-family: 'Raleway', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 24px;
}

#change-account {
  .change-account__navbar-change-account {
    position: absolute;
    top: 0;
    left: 0;
  }

  .change-account__header {
    width: 100%;
    margin-top: 84px;

    h1 {
      font-weight: 600;
      font-size: 36px;
      line-height: 50px;
      color: #4c4541;
      text-align: center;
    }

    h2 {
      font-weight: 400;
      font-size: 22px;
      line-height: 30px;
      color: #998f8a;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .cards-container {
    margin: 24px 0;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 24px 30px;

    .card-container {
      background: #fafafa;
      border: 1px solid #cfc4be;
      box-shadow: 0px 4px 24px rgba(58, 46, 38, 0.1);
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 27px 0;
      cursor: pointer;

      &:hover {
        background: #ffede2;
        border: 1px solid #974900;
      }

      &:active {
        background: #ffdbc4;
        border: 1px solid #974900;
      }

      &:hover,
      &:active {
        &.add-account-container {
          user-select: none;

          .add-account {
            color: #974900;
          }

          svg {
            path {
              fill: #974900;
            }
          }
        }
      }

      &.add-account-container {
        display: flex;
        flex-direction: column;

        .add-account {
          font-weight: 600;
          font-size: 22px;
          line-height: 30px;
          color: #998f8a;
          margin-top: 8px;
        }
      }

      .add-ellipsis-change-account {
        white-space: nowrap;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .btn-close {
        position: absolute;
        right: -6.5px;
        top: -6.5px;

        display: flex;
        align-items: center;
        justify-content: center;

        background: #fafafa;
        border: 1px solid #cfc4be;
        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        user-select: none;
        width: 34px;
        height: 34px;
        padding: 5px;
      }

      .card-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .account-photo {
          overflow: hidden;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background: #ffc697;
          position: relative;
          user-select: none;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          span {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: #512400;
            font-size: 36px;
            line-height: 50px;
            font-weight: 600;
            text-transform: uppercase;
          }
        }

        .account-details {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 8px;
          width: 100%;
          overflow: hidden;
          padding: 0 20px;

          .account-name {
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
            color: #4c4541;
            max-width: 100%;
          }

          .account-info {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #998f8a;
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) and (min-width: 570px) {
  #change-account {
    .cards-container {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

@media (max-width: 570px) {
  #change-account {
    .change-account__header {
      h1 {
        font-size: 22px;
        line-height: 30px;
      }

      h2 {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .cards-container {
      grid-template-columns: minmax(0, 1fr);

      .card-container {
        justify-content: start;
        padding: 15px 16px;

        &.add-account-container {
          flex-direction: row;
          justify-content: center;

          svg {
            margin-top: -4px;
          }

          .add-account {
            font-size: 16px;
            line-height: 26px;
            margin: 0 0 0 8px;
          }
        }

        .btn-close {
          width: 24px;
          height: 24px;
        }

        .card-content {
          flex-direction: row;

          .account-photo {
            max-width: 60px;
            max-height: 60px;
            min-width: 60px;
            min-height: 60px;

            span {
              font-size: 22px !important;
              line-height: 30px !important;
            }
          }

          .account-details {
            align-items: start;
            margin: 0 0 0 16px;
            padding: 0 10px 0 0;

            .account-name {
              font-size: 16px;
              line-height: 26px;
            }

            .account-info {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
